import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import './InvitationPage.css'
import QRCode from 'qrcode.react';
import { PDFDownloadLink, Font, Document, Page, View, Text, Image } from '@react-pdf/renderer'
import QRious from 'qrious';
import ErrorPage from '../utils/ErrorPage.js';
import LoadingPage from '../utils/LoadingPage.js';
import States from '../utils/states';

Font.register({
    family: "Cairo",
    src: 'https://fonts.gstatic.com/s/cairo/v6/SLXGc1nY6HkvamIm.ttf'
});

Font.register({
    family: "Cairo Bold",
    src: "https://fonts.gstatic.com/s/cairo/v6/SLXLc1nY6Hkvalqaa76M.ttf"
})
function PDFTemplate(props) {
    const styles = {
        page: {
            paddingTop: 30,
            // paddingBottom: 65,
            paddingHorizontal: 50,
            backgroundColor: "#77d8d8",
            fontFamily: "Cairo Bold",
            textAlign: "center"
        },
        info: {
            color: "#eff3c6",
            fontSize: 20
        },
        name: {
            color: "#0779e4"

        },
        location: {
            backgroundColor: props.reservation.event.location.color,
            color: "white",
            marginHorizontal: -50,
        },
        code: {}
    };
    var qr = new QRious({
        background: "#77d8d8",
        // backgroundAlpha: 0.8,
        foreground: "#0779e4",
        // foregroundAlpha: 0.8,
        level: 'Q',
        padding: 25,
        size: 150,
        value: props.reservation.reservationId
    });
    const qrImage = qr.toDataURL();
    return (
        <Document>
            <Page size="A5" style={styles.page}>
                <View style={styles.info}>
                    <Text style={styles.name}>{props.reservation.user.displayName}</Text>
                    <Text>{props.reservation.event.name}</Text>
                    <Text>{(new Date(props.reservation.event.start)).toLocaleDateString('fr')}</Text>
                    <Text>{(new Date(props.reservation.event.start)).toLocaleTimeString('en-UK', { timeZone: 'Africa/Cairo', hour: '2-digit', minute: '2-digit', hour12: false })} من</Text>
                    <Text>{(new Date(props.reservation.event.end)).toLocaleTimeString('en-UK', { timeZone: 'Africa/Cairo', hour: '2-digit', minute: '2-digit', hour12: false })} إلى</Text>
                    <Text style={styles.location}>{props.reservation.event.location.name} فى </Text>
                </View>
                <View style={styles.code}>
                    <Image src={qrImage}></Image>
                </View>
            </Page>
        </Document>
    );
}

function InvitationPage() {
    const reservation = useStoreState(state => state.reservation.reservation);
    const getLastReservation = useStoreActions(actions => actions.reservation.getLastReservation);
    const error = useStoreState(state => state.reservation.error);
    const state = useStoreState(state => state.reservation.state);
    const setState = useStoreActions(actions => actions.reservation.setState);

    // eslint-disable-next-line default-case
    switch (state) {
        case States.INITIAL:
            getLastReservation()


        // eslint-disable-next-line no-fallthrough
        case States.LOADING:
            return <LoadingPage />

        case States.ERROR:
            return <ErrorPage message={error} resetError={() => setState(States.INITIAL)} />;


        case States.SUCCESS:
            return (
                <React.Fragment>
                    <PDFDownloadLink className="Button FirstColor Download ButtonText" document={<PDFTemplate reservation={reservation} />} fileName="invitation.pdf">
                        {({ blob, url, loading, error }) => (loading ? '... برجاء الإنتظار قليلاً...' : 'تحميل الدعوة')}
                    </PDFDownloadLink>
                    <div id="invitation" className="Panel Reservation ThirdColor" >
                        <div className="ReservationInfo">
                            <div className="PersonName">
                                <h1>
                                    {reservation.user.displayName}
                                </h1>
                            </div>
                            <div className="Details">
                                <h2>
                                    {reservation.event.name}
                                </h2>
                            </div>
                            <div className="Details">
                                <h2>
                                    {(new Date(reservation.event.start)).toLocaleDateString('ar-EG')}
                                </h2>
                            </div>
                            <div className="Details">
                                <h2>
                                    {(new Date(reservation.event.start)).toLocaleTimeString('ar-EG', { timeZone: 'Africa/Cairo', hour: '2-digit', minute: '2-digit', hour12: false })}
                        &#8678;
                        {(new Date(reservation.event.end)).toLocaleTimeString('ar-EG', { timeZone: 'Africa/Cairo', hour: '2-digit', minute: '2-digit', hour12: false })}
                                </h2>
                            </div>
                            <div className="Location" style={{ background: reservation.event.location.color, color: "white", fontWeight: "bold" }} >
                                <h2>
                                    فى {reservation.event.location.name}
                                </h2>
                            </div>
                        </div>
                        <div className="ReservationCode">
                            <QRCode size={256} level="Q" fgColor="#0779e4" bgColor="#77d8d8" renderAs="svg" value={reservation.reservationId} />
                        </div>
                    </div>
                </React.Fragment>
            );
    }
}

export default InvitationPage;
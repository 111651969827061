import React, { useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { StoreProvider, useStoreActions } from 'easy-peasy';
import store from './logic/store.js';
import RequireAuth from './utils/RequireAuth.js'
import ErrorPage from './utils/ErrorPage.js';
import LandingPage from './pages/LandingPage.js';
import LoginPage from './pages/LoginPage.js';
import ReservationPage from './pages/ReservationPage.js';
import ConfirmationPage from './pages/ConfirmationPage.js';
import InvitationPage from './pages/InvitationPage.js';


function AppHelper() {
  const startAuthListener = useStoreActions(actions => actions.user.startAuthListener);


  useEffect(() => {
    startAuthListener();
  });

  return (
    <div className="Background AccentColor">
      <Router>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/error">
            <ErrorPage />
          </Route>
          <Route path="/reserve/:index">
            <RequireAuth>
              <ConfirmationPage />
            </RequireAuth>
          </Route>
          <Route path="/reserve">
            <RequireAuth>
              <ReservationPage />
            </RequireAuth>
          </Route>
          <Route path="/invitation">
            <RequireAuth>
              <InvitationPage />
            </RequireAuth>
          </Route>
          {/* <Route path="/manage">
            <div>MANAGE</div>
          </Route> */}
          <Route path="/">
            <LandingPage />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

function App() {
  return (
    <StoreProvider store={store}>
      <AppHelper />
    </StoreProvider>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import './LoginPage.css';
import { useStoreState, useStoreActions } from 'easy-peasy';
import {
    useLocation, Redirect
} from "react-router-dom";
import ErrorPage from '../utils/ErrorPage.js';
import LoadingPage from '../utils/LoadingPage.js';
import States from '../utils/states.js';


function LoginPage() {
    const location = useLocation();
    const user = useStoreState(state => state.user.user);
    const error = useStoreState(state => state.user.error);
    const state = useStoreState(state => state.user.state);
    const setState = useStoreActions(actions => actions.user.setState);
    const login = useStoreActions(actions => actions.user.login);
    const [accessCode, setAccessCode] = useState('');

    useEffect(() => {
        if (!user
            && location?.state?.hash?.includes('code=')) {
            const code = decodeURIComponent(location.state.hash.split("code=")[1])
            setAccessCode(code);
        }
    }, [user, location])

    const handleSubmit = async () => {
        try {
            await login(accessCode);
        } catch (err) {
            console.log(err);
        }
    };

    // eslint-disable-next-line default-case
    switch (state) {
        case States.LOADING:
            return <LoadingPage />


        case States.ERROR:
            return <ErrorPage message={error} resetError={() => setState(States.INITIAL)} />;


        case States.SUCCESS:
            return <Redirect to={{
                pathname: location.state.from,
                state: { from: location.pathname }
            }} />;


        case States.INITIAL:
            return (
                <div className="Panel Login ThirdColor">
                    <div className="LoginTitle">
                        <h2>برجاء إدخال الكود الشخصى</h2>
                    </div>
                    <div className="LoginForm" onSubmit={login}>
                        <label className="FormField">
                            <input
                                // disabled={submit}
                                dir="ltr"
                                type="text"
                                required="required"
                                name="accessCode"
                                value={accessCode}
                                onChange={e => setAccessCode(e.target.value)
                                } />
                        </label>

                        <div
                            className="Button LoginButton FirstColor"
                            // disabled={submit}
                            onClick={async () => await handleSubmit()}
                        >
                            <div className="ButtonText">
                                تسجيل الدخول
                            </div>
                        </div>
                    </div>

                </div>
            );
    }
}

export default LoginPage;
const ErrorCodes = {
    UNAUTHORIZED: 'غير مسموح بالدخول, برجاء التأكد من صحة البيانات أو التحدث لأحد الخدام.',
    INTERNAL_ERROR: 'خطأ بالنظام, برجاء التحدث لأحد الخدام.',
    MISSING_INPUT: 'معلومات ناقصة, برجاء التأكد من صحة البيانات.',
    RESERVATION_NOT_FOUND: 'لا يوجد حجز فعال.',
    RESERVATION_LIMIT_EXCEEDED: 'لقد تخطيت حد الحضور المسموح به, يسمح بحضور قداس واحد فقط على مدار إسبوعين.',
    EVENT_NOT_FOUND: 'لم نجد هذا القداس.',
    EVENT_ALREADY_STARTED: 'لقد بدأ القداس, لا يمكنك الحضور الآن.',
    EVENT_AT_FULL_CAPACITY: 'هذا القداس كامل العدد.',
    ZERO_EVENTS: 'لا يوجد مواعيد للحجز حالياً.',
}

export default ErrorCodes;
import React from 'react';
import './ErrorPage.css';

function ErrorPage(props) {
    return (
        <div className="Panel ErrorPanel">
            <div className="LoadingTitle">
                <h1>لقد حدثَ خطبٌ ما!</h1>
            </div>
            <img className="ErrorImage" alt="" src={process.env.PUBLIC_URL + '/illustrations/error2.svg'}></img>
            <div className="ErrorMessage">
                <h2>{props.message}</h2>
            </div>

            {
                props.resetError ?
                    <div className="Button ButtonText FirstColor ErrorButton" onClick={props.resetError}>
                        حاول مرة اخرى
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default ErrorPage;
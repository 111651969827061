import React from 'react';
import { useStoreState } from 'easy-peasy';
import {
    Redirect, useLocation
} from "react-router-dom";


function RequireAuth(props) {
    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
    const location = useLocation();

    return isLoggedIn ?
        <React.Fragment>
            {props.children}
        </React.Fragment>
        :
        <Redirect to={{
            pathname: "/login",
            state: { from: location.pathname, hash: location.hash }
        }} />;
}

export default RequireAuth;
import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import './ReservationPage.css'
import { useHistory } from 'react-router-dom';
import ErrorPage from '../utils/ErrorPage.js';
import LoadingPage from '../utils/LoadingPage.js';
import States from '../utils/states';

function ReservationPage() {
    const events = useStoreState(state => state.events.events);
    const fetchEvents = useStoreActions(actions => actions.events.fetchEvents);
    const history = useHistory();
    const error = useStoreState(state => state.events.error);
    const state = useStoreState(state => state.events.state);
    const setState = useStoreActions(actions => actions.events.setState);

    const handleEventSelect = (index) => {
        history.push(`/reserve/${index}`);
    };

    // eslint-disable-next-line default-case
    switch (state) {
        case States.INITIAL:
            fetchEvents();
        // eslint-disable-next-line no-fallthrough
        case States.LOADING:
            return <LoadingPage />


        case States.ERROR:
            return <ErrorPage message={error} resetError={() => setState(States.INITIAL)} />


        case States.SUCCESS:
            return (
                <div className="Panel EventsPanel ThirdColor">
                    {
                        events.map((event, index) => {
                            return <div
                                key={event.eventId}
                                disabled={event.remaining === 0}
                                className="Button SecondColor Event"
                                onClick={() => handleEventSelect(index)}>
                                <div className="EventTitle">
                                    <div className="EventName">
                                        {event.name}
                                    </div>
                                    <div className="EventDate">
                                        {(new Date(event.start.dateTime)).toLocaleDateString('ar-EG')}
                                    </div>
                                </div>
                                <div className="EventBody">
                                    <div className="EventTime">
                                        {(new Date(event.start.dateTime)).toLocaleTimeString('ar-EG', { timeZone: event.start.timeZone, hour: '2-digit', minute: '2-digit', hour12: false })}
                                        &#8678;
                                        {(new Date(event.end.dateTime)).toLocaleTimeString('ar-EG', { timeZone: event.start.timeZone, hour: '2-digit', minute: '2-digit', hour12: false })}
                                    </div>
                                    <div className="EventCapacity">
                                        باقى {event.remaining} مكان
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            );
    }
}


export default ReservationPage;
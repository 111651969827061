import React from 'react';
import './LandingPage.css';
import {
    useHistory
} from "react-router-dom";

function LandingPage() {
    let history = useHistory();

    return (
        <div className="Panel ThirdColor">
            <div className="Title">
                <h1>تسجيل حضور قداسات كنيسة السيدة العذراء بالرحاب</h1>
            </div>
            <div className="Menu">
                {/* <div className="Register Button FirstColor" onClick={() => { history.push("/register") }}>
                    <div className="ButtonText">
                        تسجيل
                    </div>
                </div> */}
                <div className="Button Reserve FirstColor" onClick={() => { history.push("/reserve") }}>
                    <div className="ButtonText">
                        حضور القداس
                    </div>
                </div>
                <div className="Button Invitation FirstColor" onClick={() => { history.push("/invitation") }}>
                    <div className="ButtonText">
                        حجزى السابق
                    </div>
                </div>
                <div disabled className="Button Manage SecondColor" onClick={() => { history.push("/manage") }}>
                    <div className="ButtonText">
                        متابعة (للمنظمين)
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';

console.log("Initializing firebase");

export const firebaseApp = firebase.initializeApp({
    apiKey: 'AIzaSyDn2zGUrH7losSpRP2xagnU20P8vnJs7T8',
    authDomain: 'st-mary-rehab-events.firebaseapp.com'
});


export const EMAIL_SUFFIX = '@stmary-rehab.com';

const BASE_URL = 'https://europe-west1-st-mary-rehab-events.cloudfunctions.net/app';
// const BASE_URL = 'http://localhost:5001/st-mary-rehab-events/europe-west1/app';
export const FETCH_EVENTS_URL = `${BASE_URL}/events/`;
export const RESERVE_EVENT_URL = `${BASE_URL}/reservations/`;



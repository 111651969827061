import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import './ConfirmationPage.css'
import {
    Redirect,
    useParams,
    useHistory
} from "react-router-dom";
import ErrorPage from '../utils/ErrorPage.js';
import LoadingPage from '../utils/LoadingPage.js';
import States from '../utils/states';

function ConfirmationPage() {
    const events = useStoreState(state => state.events.events);
    // const reservation = useStoreState(state => state.reservation.reservation);
    const error = useStoreState(state => state.reservation.error);
    const reserve = useStoreActions(actions => actions.reservation.reserve);
    const state = useStoreState(state => state.reservation.state);
    const setState = useStoreActions(actions => actions.reservation.setState);
    const history = useHistory();
    const { index } = useParams();
    const selectedEvent = events[index];

    const handleEventDeSelect = () => {
        history.goBack();
    };
    const handleEventReserve = async () => {
        reserve(selectedEvent.eventId);
        // history.push('/invitation', { eventId: selectedEvent.eventId });
    };

    // eslint-disable-next-line default-case
    switch (state) {
        case States.INITIAL:
            return (
                <div className="Panel ThirdColor">
                    <div className="SelectedEventTitle">
                        <h1>
                            {selectedEvent.name}
                        </h1>
                    </div>
                    <div className="SelectedEventTime">
                        <h2>
                            {(new Date(selectedEvent.start.dateTime)).toLocaleDateString('ar-EG')}
                        </h2>
                    </div>
                    <div className="SelectedEventTime">
                        <h2>
                            {(new Date(selectedEvent.start.dateTime)).toLocaleTimeString('ar-EG', { timeZone: selectedEvent.start.timeZone, hour: '2-digit', minute: '2-digit', hour12: false })}
                                &#8678;
                                {(new Date(selectedEvent.end.dateTime)).toLocaleTimeString('ar-EG', { timeZone: selectedEvent.start.timeZone, hour: '2-digit', minute: '2-digit', hour12: false })}
                        </h2>
                    </div>
                    <div className="SelectedEventTime">
                        <h2>
                            باقى {selectedEvent.remaining} مكان
                            </h2>
                    </div>
                    <div className="Button ButtonReserve FirstColor" onClick={() => handleEventReserve()}>
                        <div className="ButtonText">تأكيد الحجز</div>
                    </div>
                    <div className="Button ButtonCancel SecondColor" onClick={() => handleEventDeSelect()}>
                        <div className="ButtonText">رجوع</div>
                    </div>
                </div>
            );


        case States.ERROR:
            return <ErrorPage message={error} resetError={() => setState(States.INITIAL)} />


        case States.SUCCESS:
            // return <Invitation reservation={reservation} />;\
            return <Redirect to={{
                pathname: "/invitation"
            }} />


        case States.LOADING:
            return <LoadingPage />
    }
}


export default ConfirmationPage;
import { createStore, action, computed, thunk } from 'easy-peasy';
import { firebaseApp, EMAIL_SUFFIX, FETCH_EVENTS_URL, RESERVE_EVENT_URL } from './firebase';
import ErrorCodes from '../utils/errors.js';
import States from '../utils/states.js';


const EventsModel = {
    error: null,
    events: null,
    state: States.INITIAL,
    setState: action((state, payload) => {
        state.state = payload;
    }),
    setError: action((state, payload) => {
        state.error = payload;
    }),
    addEvents: action((state, payload) => {
        state.events = payload;
    }),
    fetchEvents: thunk(async (actions, _payload) => {
        actions.setState(States.LOADING);
        const authToken = await firebaseApp.auth().currentUser.getIdToken();
        try {
            const response = await fetch(FETCH_EVENTS_URL, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            if (response.status === 200) {
                const data = await response.json();

                if (data.length === 0) {
                    actions.setError(ErrorCodes.ZERO_EVENTS);
                    actions.setState(States.ERROR);
                } else {
                    actions.addEvents(data);
                    actions.setState(States.SUCCESS);
                }
            } else {
                const errorCode = await response.text();
                actions.setError(ErrorCodes[errorCode]);
                actions.setState(States.ERROR);
            }
        } catch (err) {
            console.log(err);
        }
    })
};

const ReservationModel = {
    error: null,
    reservation: null,
    state: States.INITIAL,
    setState: action((state, payload) => {
        state.state = payload;
    }),
    setError: action((state, payload) => {
        state.error = payload;
    }),
    addReservation: action((state, payload) => {
        state.reservation = payload;
    }),
    getLastReservation: thunk(async (actions, _payload) => {
        actions.setState(States.LOADING);
        try {
            const authToken = await firebaseApp.auth().currentUser.getIdToken();
            const response = await fetch(RESERVE_EVENT_URL, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-type': 'application/json'
                }
            });
            if (response.status === 200) {
                const data = await response.json();
                actions.addReservation(data);
                actions.setState(States.SUCCESS);
            } else {
                const errorCode = await response.text();
                actions.setError(ErrorCodes[errorCode]);
                actions.setState(States.ERROR);
            }
        } catch (err) {
            console.log(err);
        }
    }),
    reserve: thunk(async (actions, payload) => {
        actions.setState(States.LOADING);
        try {
            const authToken = await firebaseApp.auth().currentUser.getIdToken();
            const response = await fetch(RESERVE_EVENT_URL, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    eventId: payload
                })
            });
            if (response.status === 201) {
                const data = await response.json();
                actions.addReservation(data);
                actions.setState(States.SUCCESS);
            } else {
                const errorCode = await response.text();
                actions.setError(ErrorCodes[errorCode]);
                actions.setState(States.ERROR);
            }
        } catch (err) {
            console.log(err);
        }
    }),
}



const UserModel = {
    error: null,
    user: null,
    isLoggedIn: computed(state => state.user != null),
    state: States.INITIAL,
    setState: action((state, payload) => {
        state.state = payload;
    }),
    setUser: action((state, payload) => {
        state.user = payload;
    }),
    setError: action((state, payload) => {
        state.error = payload;
    }),
    startAuthListener: thunk((actions, _payload) => {
        firebaseApp.auth().onAuthStateChanged((user) => {
            if (user) {
                actions.setUser(user);
                actions.setState(States.SUCCESS);
            }
        });
    }),
    logout: thunk(async (actions, _payload) => {
        actions.setState(States.LOADING);
        try {
            await firebaseApp.auth().signOut();
            actions.setUser(null);
            actions.setState(States.SUCCESS);
        } catch (err) {
            console.log(err);
            actions.setState(States.ERROR);
        }
    }),
    login: thunk(async (actions, payload) => {
        actions.setState(States.LOADING);
        const accessCode = payload;
        const creds = accessCode.split('#');
        const email = `${creds[0]}${EMAIL_SUFFIX}`;
        const password = creds[1];

        if (accessCode) {
            try {
                await firebaseApp.auth().setPersistence('session');
                await firebaseApp.auth().signInWithEmailAndPassword(email, password);
            } catch (err) {
                console.log(err);
                actions.setError(ErrorCodes.UNAUTHORIZED);
                actions.setState(States.ERROR);
            }
        } else {
            console.log('No access code provided');
            actions.setError(ErrorCodes.MISSING_INPUT);
            actions.setState(States.ERROR);
        }
    })
}


const store = createStore({
    events: EventsModel,
    reservation: ReservationModel,
    user: UserModel
});

export default store;
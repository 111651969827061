import React from 'react';
import './LoadingPage.css';

const IMAGES = [
    '/illustrations/super1.svg',
    '/illustrations/automaton-board1.svg',
    '/illustrations/automaton-factory1.svg',
    '/illustrations/hourglass1.svg',
];

function LoadingPage() {
    const randomInt = Math.floor(Math.random() * IMAGES.length);
    console.log(randomInt);
    const imagePath = process.env.PUBLIC_URL + IMAGES[randomInt];

    return (
        <div className="Panel LoadingPanel">
            <img alt="" className="LoadingImage" src={imagePath}></img>
            <div className="LoadingTitle">
                <h1>
                    ...برجاء الإنتظار قليلاً...
                    </h1>
            </div>
        </div>
    )
}

export default LoadingPage;